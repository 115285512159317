<template>
  <div class="public-job-board-config mb-4">
    <h4 class="text-primary mt-2 mb-4">Public Job Board - Configurations</h4>
    <ValidationObserver ref="advert" v-slot="{ handleSubmit }">
      <form id="advert" @submit.prevent="handleSubmit(onSubmit)">
        <CCard>
          <CCardBody>
            <CRow class="row mb-3">
              <label class="col-lg-12 col-md-12"
                >Upload Image
                <small class="text-primary"
                  >(Tip: Upload the image to be shown in the scrolling
                  advertisement section)</small
                >
              </label>
              <div class="col-lg-12 col-md-12 col-sm-12 upload_file">
                <input
                  id="upload-image"
                  name="upload-image"
                  type="file"
                  ref="fileref"
                  :accept="fileType"
                  @change="handleFile"
                />
                <label
                  for="upload-image"
                  class="btn text-white small font-weight-bolder rounded-2"
                  >{{
                    payload && payload.file ? "Select New File" : "Select File"
                  }}</label
                >
                <small
                  class="ml-2"
                  style="position: relative; top: 3px; font-size: 13px"
                >
                  <input
                    type="checkbox"
                    :value="useCrop"
                    :checked="useCrop"
                    @input="useCrop = !useCrop"
                    class="mr-1"
                    id="crop_check"
                  />
                  <label for="crop_check" style="position: relative; top: -2px"
                    >Use Crop</label
                  >
                </small>
                <small class="ml-2" v-if="payload && payload.file"
                  >(file Selected)</small
                >
              </div>
              <div v-if="cropperModal.isShowPopup">
                <CropperModal
                  :isShowPopup="cropperModal.isShowPopup"
                  :fileData="cropperModal.fileData"
                  modalColor="primary"
                  :buttonLabel="cropperModal.buttonLabel"
                  @modalCallBack="cropperModalCallBack"
                />
              </div>
            </CRow>
            <CRow>
              <CCol lg="12">
                <CRow class="row mb-3">
                  <CCol lg="12" md="12">
                    <label class="required">URL</label>
                    <small class="text-primary"
                      >(Tip: Provide the web URL or link for navigation if user
                      clicks on the image)</small
                    >
                  </CCol>
                  <div class="col-lg-6 col-md-6 col-sm-12">
                    <ValidationProvider rules="required" v-slot="{ errors }">
                      <TextInput
                        name="link"
                        :value="advert.link"
                        @input="handleInput"
                        :error="errors[0]"
                      />
                    </ValidationProvider>
                  </div>
                </CRow>
              </CCol>
            </CRow>
            <CRow class="justify-content-end pr-3 mb-3">
              <label class="btn font-weight-bolder rounded-2">
                Advertisement Status
              </label>
              <CSwitch
                color="success"
                :checked="jobAdvertisement"
                shape="pill"
                variant="3d"
                size="sm"
                class="mr-3 mt-1"
                @change.native="handleOffAdvertisement($event)"
              />
              <CButton
                class="mr-3 btn-outline-primary"
                type="button"
                @click="resetForm()"
                >Cancel</CButton
              >
              <CButton class="btn-primary" type="submit">{{
                advert.job_advertisement_id ? `Update` : "Add"
              }}</CButton>
            </CRow>
            <div class="documentScrolling">
              <table class="table">
                <thead>
                  <tr>
                    <th>URL</th>
                    <th>Preview</th>
                    <th>Edit</th>
                    <th>Delete</th>
                    <th>On/Off</th>
                  </tr>
                </thead>
                <tbody>
                  <tr v-for="(advert, index) in jobAdvertisements" :key="index">
                    <td>{{ advert.link }}</td>
                    <td>
                      <CButton variant="ghost" @click="preview(advert)">
                        <i class="fas fa-eye"></i>
                      </CButton>
                    </td>
                    <td>
                      <CButton variant="ghost" @click="editAdvert(advert)">
                        <i class="far fa-edit"></i>
                      </CButton>
                    </td>
                    <td>
                      <CButton variant="ghost" @click="deleteAdvert(advert)">
                        <i class="fas fa-trash"></i>
                      </CButton>
                    </td>
                    <td>
                      <CSwitch
                        :key="index"
                        color="success"
                        :checked="advert.is_active"
                        shape="pill"
                        variant="3d"
                        size="sm"
                        @change.native="handleChangeCheck(advert, $event)"
                      />
                    </td>
                  </tr>
                  <tr v-if="!jobAdvertisements.length">
                    <td colspan="5">
                      <p class="text-center text-muted h6 mt-3">
                        No Records Found
                      </p>
                    </td>
                  </tr>
                </tbody>
              </table>
            </div>
          </CCardBody>
        </CCard>
        <div class="text-center mx-0 action-area">
          <CButton
            type="button"
            name="registration-home"
            class="btn rounded-0 px-5 btn-lg btn-outline-primary btn-lg"
            @click="navToDashboard"
          >
            Home
          </CButton>
        </div>
      </form>
    </ValidationObserver>
    <CToaster v-if="errorToaster.isError" position="bottom-right">
      <CToast :show="true" class="bg-danger text-white">{{
        errorToaster.message
      }}</CToast>
    </CToaster>
    <PreviewModal
      :modalTitle="previewModal.modalTitle"
      :modalColor="previewModal.modalColor"
      :isShowPopup="previewModal.isShowPopup"
      :buttons="previewModal.buttons"
      :modalCallBack="previewModalCallBack"
      :isPreview="previewModal.isPreview"
      :iframe="iframe"
      :size="previewModal.size"
      :closeOnBackdrop="previewModal.closeOnBackdrop"
    />
    <Modal
      v-if="deleteModal.isShowPopup"
      :modalTitle="deleteModal.modalTitle"
      :modalColor="deleteModal.modalColor"
      :modalContent="deleteModal.modalContent"
      :isShowPopup="deleteModal.isShowPopup"
      :buttons="deleteModal.buttons"
      :modalCallBack="deleteModalCallBack"
      :closeOnBackdrop="false"
    />
  </div>
</template>
<script>
import CropperModal from "@/components/reusable/CropperModal";
import TextInput from "@/components/reusable/Fields/TextInput";
import { extend } from "vee-validate";
import { required } from "vee-validate/dist/rules";
import PreviewModal from "@/components/reusable/PreviewModal";
import Vue from "vue";
import { mapActions, mapGetters } from "vuex";
extend("required", { ...required, message: "This field is required" });
import Modal from "@/components/reusable/Modal";
import m from "moment";

export default {
  components: {
    CropperModal,
    TextInput,
    PreviewModal,
    Modal,
  },
  data() {
    return {
      advert: {},
      payload: {},
      fileType: "image/png, image/jpeg, image/jpg, image/bmp",
      cropperModal: {
        isShowPopup: false,
        fileData: null,
        buttonLabel: "Apply",
      },
      errorToaster: {
        isError: false,
        message: null,
      },
      previewModal: {
        modalColor: "primary",
        modalTitle: "",
        isShowPopup: false,
        buttons: ["Close"],
        isPreview: false,
        size: "lg",
        closeOnBackdrop: false,
      },
      iframe: {
        src: "",
        loaded: false,
        isImg: false,
        pdf: {
          ispdf: true,
          src: "",
          numPages: undefined,
          addContentClasses: "",
        },
      },
      deleteModal: {
        modalColor: "danger",
        modalTitle: "Delete Job Advertisement",
        modalContent: "Please confirm you wish to delete this advert?",
        buttons: ["Cancel", "Confirm"],
        job_advertisement_id: null,
        isShowPopup: false,
      },
      useCrop: true,
      jobAdvertisement: false,
    };
  },
  computed: {
    ...mapGetters(["jobAdvertisements"]),
  },
  methods: {
    ...mapActions([
      "fetchJobAdvertisement",
      "showToast",
      "uploadAdvert",
      "deleteJobAdvert",
      "updateAdvert",
      "getCustomerVariant",
      "updateAdverticementStatus",
      "updateFacilityConfig",
    ]),
    navToDashboard() {
      this.$router.push({
        path: "/dashboard",
      });
    },
    getExtension(filename) {
      let parts = filename.split(".");
      return parts[parts.length - 1];
    },
    handleInput(name, value) {
      Vue.set(this.advert, name, value);
      Vue.set(this.payload, name, value);
    },
    handleFile(event) {
      this.isFileError = false;
      this.isFileTpeValid = false;
      const size = event.target.files[0].size;
      if (Math.round(size / (1024 * 1024)) <= 2) {
        const file = event.target.files[0];
        const fileExt = this.getExtension(file.name);
        const fileTypeString = this.fileType;
        if (fileTypeString.includes(fileExt)) {
          if (file.type.startsWith("image/")) {
            if (this.useCrop) {
              this.cropperModal.fileData = file;
              this.cropperModal.isShowPopup = true;
            } else {
              this.payload = {
                ...this.payload,
                file,
                fileName: m().format("YYYYMMDDHHmmss").concat(`_${file.name}`),
              };
            }
          }
        } else {
          const message = "Only png/jpeg allowed";
          this.showToaster({ message });
        }
      } else {
        const message = "File too Big, please select a file less than 2mb";
        this.showToaster({ message });
      }
    },
    cropperModalCallBack(action, data) {
      if (action === false) {
        this.cropperModal.isShowPopup = false;
        this.cropperModal.fileData = null;
        this.$refs.fileref.value = "";
      } else {
        let { fileBlob, fileName } = data;
        fileName = m().format("YYYYMMDDHHmmss").concat(`_${fileName}`);
        this.payload = { ...this.payload, fileName };
        this.payload.file = fileBlob;
        this.cropperModal.isShowPopup = false;
        this.cropperModal.fileData = null;
      }
    },
    showToaster({ message }) {
      this.$refs.fileref.value = "";
      this.errorToaster.isError = true;
      this.errorToaster.message = message;
      setTimeout(() => {
        this.errorToaster.isError = false;
        this.errorToaster.message = null;
      }, 5000);
    },
    preview(data) {
      this.previewModal.modalTitle = "Image Preview";
      this.iframe.loaded = false;
      this.iframe.ispdf = false;
      this.iframe.isImg = true;
      this.iframe.pdf.ispdf = false;
      this.iframe.src = data.base64_image_data || "";
      this.previewModal.isPreview = true;
      this.previewModal.isShowPopup = true;
    },
    deleteAdvert(data) {
      const { id } = data;
      this.deleteModal.isShowPopup = true;
      this.deleteModal.job_advertisement_id = id;
    },
    editAdvert(data) {
      const { id: job_advertisement_id, link } = data;
      this.advert = { link, job_advertisement_id };
    },
    previewModalCallBack() {
      this.iframe.src = "";
      this.iframe.loaded = false;
      this.iframe.isImg = false;
      this.iframe.pdf.ispdf = false;
      this.previewModal.isShowPopup = false;
    },
    async onSubmit() {
      const isValid = await this.$refs.advert.validate();
      if (!isValid) {
        this.showToast({
          class: "bg-danger text-white",
          message: "please fill mandatory fields!",
        });
        return;
      }
      if (!this.payload.file && !this.advert.job_advertisement_id) {
        this.showToast({
          class: "bg-danger text-white",
          message: "please Choose Image File!",
        });
      } else {
        let appendAction = null;
        if (this.advert?.job_advertisement_id) {
          let finalPayload = {
            link: this.advert?.link,
            job_advertisement_id: this.advert?.job_advertisement_id,
            is_active: this.advert?.is_active,
          };
          if (this.payload?.file)
            finalPayload = {
              ...finalPayload,
              file: this.payload?.file,
              fileName: this.payload?.fileName,
            };
          appendAction = this.updateAdvert(finalPayload);
        } else {
          let finalPayload = {
            link: this.payload?.link,
            file: this.payload?.file,
            fileName: this.payload?.fileName,
            is_active: true,
          };
          appendAction = this.uploadAdvert(finalPayload);
        }
        Promise.all([appendAction]).then((res) => {
          this.fetchJobAdvertisement();
          this.resetForm();
        });
      }
    },
    resetForm() {
      this.$refs.fileref.value = "";
      this.advert = {};
      this.payload = {};
      this.$nextTick(() => this.$refs.advert.reset());
    },
    deleteModalCallBack(action) {
      if (action === "Confirm") {
        const { job_advertisement_id } = this.deleteModal;
        this.deleteJobAdvert(job_advertisement_id).then((res) => {
          this.fetchJobAdvertisement();
        });
      }
      this.deleteModal.isShowPopup = false;
    },
    handleChangeCheck(item, $event) {
      const is_active = $event.target.checked;
      const { id: job_advertisement_id, customer_id, organisation_id } = item;

      const payload = {
        job_advertisement_id,
        is_active,
        customer_id,
        organisation_id,
      };
      this.updateAdvert(payload).then((res) => {
        this.fetchJobAdvertisement();
      });
    },
    handleOffAdvertisement($event) {
      const value = $event.target.checked == true ? "TRUE" : "FALSE";
      const customer_id = 2;
      const key = "JOB_ADVERTISEMENT";
      this.updateFacilityConfig({
        key,
        value,
        customer_id: customer_id,
      });
    },
  },
  mounted() {
    this.fetchJobAdvertisement().then((res) => {
      if (res?.length) {
        this.getCustomerVariant(res[0].customer_id).then((data) => {
          this.jobAdvertisement =
            data?.filter((val) => val.key == "JOB_ADVERTISEMENT")[0].value ==
            "TRUE"
              ? true
              : false;
        });
      }
    });
  },
};
</script>
<style lang="scss" scoped>
small {
  font-size: 10px;
}
.upload_file #upload-image {
  display: none;
}
.upload_file #upload-image + label {
  display: inline-block;
  text-align: center;
  margin-bottom: 0;
  background: #085d66e6;
  border: 1px solid transparent;
  box-shadow: 0px 2px 4px 0px rgba(0, 0, 0, 0.12);
  cursor: pointer;
  font-weight: normal;
  transition: all 0.2s ease-in-out;
  width: 110px;
}
.upload_file #upload-image + label:hover {
  background: #26808ae6;
}
.upload_file #upload-image + label:after {
  color: #26808ae6;
  position: absolute;
  top: 10px;
  left: 0;
  right: 0;
  text-align: center;
  margin: auto;
}
</style>
