<template>
  <CModal
    :show.sync="isShowPopup"
    :close-on-backdrop="false"
    :color="modalColor"
    size="lg"
  >
    <template #header>
      <h6 class="modal-title">Image Crop</h6>
      <CButtonClose @click="modalCallBack(false)" class="text-black" />
    </template>
    <template #footer-wrapper>
      <div class="border-top d-flex py-3 mx-3">
        <div class="flex-grow-1 d-flex">
          <CButtonGroup class="ml-2">
            <CButton color="primary" variant="outline" @click="zoom(0.1)">
              <i class="fa fa-search-plus mr-1" />
            </CButton>
            <CButton color="primary" variant="outline" @click="zoom(-0.1)">
              <i class="fa fa-search-minus mr-1" />
            </CButton>
          </CButtonGroup>
        </div>
        <div class="d-flex">
          <CButton
            type="button"
            :color="modalColor"
            variant="outline"
            class="px-4 mx-2"
            @click="cropImage"
            >Crop</CButton
          >
          <CButton
            type="button"
            :color="modalColor"
            class="px-4 mx-2"
            @click="apply"
            :disabled="!isCroped"
            >{{ buttonLabel }}</CButton
          >
        </div>
      </div>
    </template>
    <CRow v-if="imageSrc">
      <CCol md="7" sm="12">
        <vue-cropper
          class="mr-2 w-100"
          ref="cropper"
          :guides="true"
          :aspect-ratio="aspectRatio"
          :src="imageSrc"
          :modal="true"
          :minContainerWidth="300"
          :minContainerHeight="300"
          :viewMode="1"
          :cropBoxResizable="cropBoxResizable"
        />
      </CCol>
      <CCol md="5" sm="12" class="cropped-image">
        <img class="ml-2 w-100 border" :src="croppedImageSrc" />
      </CCol>
    </CRow>
  </CModal>
</template>
<script>
import { mapActions } from "vuex";
export default {
  props: {
    isShowPopup: {
      type: Boolean,
      default: false,
    },
    fileData: {
      type: File,
      default: null,
    },
    aspectRatio: {
      type: Number,
      default: 0,
    },
    modalColor: {
      type: String,
      default: "primary",
    },
    buttonLabel: {
      type: String,
      default: "Apply",
    },
    cropBoxResizable: {
      type: Boolean,
      default: true,
    },
  },
  data() {
    return {
      isCroped: false,
      imageSrc: null,
      croppedImageSrc: null,
      fileName: null,
    };
  },
  mounted() {
    this.setImage();
  },
  methods: {
    ...mapActions(["showToast"]),
    setImage() {
      const file = this.fileData;
      this.fileName = file.name;
      if (!file.type.includes("image/")) {
        this.showToaster("Please select an image file");
        this.modalCallBack(false);
        return;
      }
      if (typeof FileReader === "function") {
        const reader = new FileReader();
        reader.onload = (event) => {
          this.imageSrc = event.target.result;
        };
        reader.readAsDataURL(file);
      } else {
        this.showToaster("Sorry, FileReader API not supported");
        this.modalCallBack(false);
      }
    },
    cropImage() {
      this.isCroped = true;
      const fileType = this.fileData.type;
      this.croppedImageSrc = this.$refs.cropper
        .getCroppedCanvas()
        .toDataURL(`'${fileType}'`);
    },
    apply() {
      this.$refs.cropper.getCroppedCanvas().toBlob((blob) => {
        let payload = {
          fileBlob: blob,
          fileName: this.fileName ? this.fileName : "untitled.png",
        };
        this.modalCallBack(true, payload);
      });
      // let payload = {
      //   fileBlob: this.$refs.cropper.getCroppedCanvas().toBlob(),
      //   fileName: this.fileName ? this.fileName : "untitled.png",
      // };
      // this.modalCallBack(true, payload);
    },
    modalCallBack(action, payload) {
      this.$emit("modalCallBack", action, payload);
    },
    showToaster(message) {
      this.showToast({
        class: "bg-danger text-white",
        message,
      });
    },
    zoom(percent) {
      this.$refs.cropper.relativeZoom(percent);
    },
  },
};
</script>