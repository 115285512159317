<template>
  <div class="config-public-job-board container-md col-lg-8 col-md-">
    <PublicJobBoardConfig />
  </div>
</template>
<script>
import PublicJobBoardConfig from "@/containers/JobBoardConfiguration/PublicJobBoardConfig";
export default {
  components: {
    PublicJobBoardConfig,
  },
};
</script>